import React from "react"
import {
  Accordion,
  Container,
  Embed,
  Grid,
  Header,
  Icon,
  Image,
  Placeholder,
  Segment,
} from "semantic-ui-react"

import ResponsiveLayout from "../components/responsive-layout"

export default () => (
  <ResponsiveLayout title="Error 404 - Page Not Found">

    <Container>

      <Header as="h1" dividing style={{ marginTop: "2em" }}>
        ERROR 404 -- PAGE NOT FOUND
      </Header>

      <h2>We couldn't find the page you requested</h2>

    </Container>

  </ResponsiveLayout>
)
